import React from 'react'
import Layout from "../components/layout"
import NewsNavBar from '../components/newsNavBar'
import { graphql, Link } from 'gatsby'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from '@contentful/rich-text-types'
import SEO from "../components/seo"
import './news.css'



export default function News(props) {
  const options = {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        return <a href={node.data.target.file.url} className="pdf-link">{children}</a>
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        return <Link href={`/exhibitions/${node.data.target.slug}`} >{children}</Link>
      }
    }
  }
    const currentYear = new Date().getFullYear()
    const currentNews =  props.data.allContentfulNewsArchive.edges.filter(object=> parseInt(object.node.year) === currentYear)
    const richText = renderRichText(currentNews[0].node.news, options)
    
    return (
        <Layout>
          <SEO 
            title={`News`}
            description={`News arhive for ${currentYear}`}
          />
          <h1 className="visibility-hidden">News</h1>
            <NewsNavBar />
                <div className="current-news-cont">{richText}</div> 
        </Layout>
    )
}

export const pageQuery = graphql
`
query newcurrent{
    allContentfulNewsArchive{
      edges {
        node {
          year
          news {
            raw
            references {
              ... on ContentfulExhibition {
                # contentful_id is required to resolve the references
                contentful_id
                title
                slug
              }
              ... on ContentfulAsset {
                contentful_id
                __typename
                file {
                  url
                  contentType
                }
              }
            }    
          }
        }
      }
    }
  }
`
