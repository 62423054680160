import React from 'react'
import {Link }from 'gatsby'

export default function NewsNavBar({slug}) {
    const currentYear = new Date().getFullYear()
    return (
        <div>
            <ul className = 'artist-nav'>
            <li className="nav-li" ><Link to='/news' activeClassName='current'>Current</Link></li>
            <li className="nav-li">|</li>
            <li className="nav-li" ><Link to={`/news/archive/${slug? slug:currentYear}`}activeClassName='current' partiallyActive={true}>Archive</Link></li>
            </ul>
        </div>
    )
}
